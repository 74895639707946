
import Vue from 'vue'
import { cloneDeep } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import firebase from 'firebase/compat/app'
import Contact from '@/components/public/Contact.vue'
import Logo from '@/components/public/Logo.vue'
import { updateBrand } from '@/services/Brand'

export default Vue.extend({
  name: 'Footer',
  components: {
    Contact,
    Logo,
  },
  props: {},
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      playstore_link: '',
      playstore_icon: null,
      isEditing: false,
      loading: false,
      footer_dialog: false,
      isHome: this.$route.path === '/',
      backgroundTypes: [
        { type: 'lineal', text: this.$t('editor.linear_color') },
        { type: 'gradient', text: this.$t('editor.gradient_color') },
      ],
      componentName: process.env.FB_PROJECT_ID,
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      landingText: 'landingtext/landingtext',
      brand: 'brand/brand',
      withWeb3: 'authconfig/withWeb3',
      isDID: 'authconfig/isDID',
    }),

    brandSettings() {
      return cloneDeep(this.brand)
    },
    footer_background() {
      return this.brandSettings.footer_background_type === 'lineal'
        ? `background-color: ${this.brandSettings.footer_bg_primary}`
        : this.brandSettings.footer_background_type === 'gradient'
        ? `background-image: linear-gradient(to bottom, ${this.brandSettings.footer_bg_primary}, ${this.brandSettings.footer_bg_secondary}`
        : 'background-image: linear-gradient(to bottom, #0096da, #035d98)'
    },
  },
  watch: {
    $route(to) {
      this.isHome = to.path === '/'
    },
  },
  async mounted() {
    if (this.isDID) await this.getPlayStoreLink()

    this.onResize()
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    ...mapMutations({
      setBrandSettings: 'brand/SET_BRAND',
    }),

    getPlayStoreLink() {
      this.$fire.firestore
        .collection('brandWallet')
        .limit(1)
        .get()
        .then((querySnapshot) => {
          this.playstore_link = querySnapshot?.docs[0]?.data().playstore_link
          this.playstore_icon = querySnapshot?.docs[0]?.data().playstore_icon
        })
    },
    async updateBrand() {
      const updatedByUid = this.$store.state.users.user.uid
      const updatedByEmail = this.$store.state.users.user.email
      const updatedAt = firebase.firestore.Timestamp.now()

      this.loading = true
      try {
        await updateBrand(this.$fire, this.brandSettings.id, {
          ...this.brandSettings,
          updatedByUid,
          updatedByEmail,
          updatedAt,
          logChanges: true,
        })
        this.setBrandSettings(this.brandSettings)
      } catch {
        this.snackbar.show = true
        this.snackbar.text = 'No se pudo guardar la actualización'
        this.snackbar.color = 'error'
      }
      this.loading = false
      this.isEditing = false
      this.footer_dialog = false
    },

    onFileSelected(event) {
      this.loading = true
      const self = this
      const storage = this.$fire.storage
      const target = event.target.id
      const file = event.target.files[0]
      const photosRef = storage.ref().child(`brand/images/${target}`)
      photosRef
        .put(file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then(function (downloadURL) {
            self.brandSettings[target] = downloadURL
          })
        })
        .catch((err) => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
        })
      this.loading = false
      this.isEditing = false
    },
    restoreDefaultLogo() {
      this.brandSettings.footer_logo =
        'https://firebasestorage.googleapis.com/v0/b/playground-gcp-201118.appspot.com/o/brand%2F4010fc67-09a4-41a8-8851-29fc2a45d6bb?alt=media&token=984cee1d-2955-4231-807d-21dfa45b8746'
    },
  },
})
