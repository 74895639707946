import { format } from 'date-fns'
import firebase from 'firebase/compat/app'
import { setDocFirestore, updateDocFirestore } from '@/services/firebaseWrite'
require('dotenv').config('../.env')

/**
 * @todo Homologate Certificate firestore properties to camelcase
 */
export interface Certificate {
  // eslint-disable-next-line camelcase
  name_citizen: string
  name: string
  // eslint-disable-next-line camelcase
  id_user: string
  // eslint-disable-next-line camelcase
  last_name: string
  last_name2: string
  dni: string
  email: string
  certify: boolean
  confirmed: boolean
  created: string
  // eslint-disable-next-line camelcase
  estado_origen: string
  // eslint-disable-next-line camelcase
  id_reference: string
  identity: string
  // eslint-disable-next-line camelcase
  is_certified: boolean
  // eslint-disable-next-line camelcase
  is_revocated: boolean
  pubkey: string
  // eslint-disable-next-line camelcase
  public_address: string
  // eslint-disable-next-line camelcase
  n_cuit: string
  ref: string
  title: string
  type: string
  levelCidi: string
}

export interface BlockcertsCertificate {
  // eslint-disable-next-line camelcase
  id_user: string
  // eslint-disable-next-line camelcase
  name_citizen: string
  // eslint-disable-next-line camelcase
  n_cuit: string
  dni: string
  email: string
  // eslint-disable-next-line camelcase
  public_address: string
  type: string
  title: string
  created: string
  // eslint-disable-next-line camelcase
  id_reference: string
  levelCidi: string
}

export const CertificateToBlockcerts = (
  certificate: Certificate
): BlockcertsCertificate => {
  // eslint-disable-next-line camelcase
  const { estado_origen, ...readyToBlockcerts } = certificate
  return {
    ...readyToBlockcerts,
    id_user: certificate.id_user,
    n_cuit: certificate?.dni || '',
    dni: certificate?.dni?.slice(2, -1) || '',
    name_citizen: certificate.name_citizen,
    public_address: certificate.public_address,
    id_reference: certificate.id_reference,
    levelCidi: certificate?.levelCidi || '',
  } as BlockcertsCertificate
}

export const CompleteCertificate = (certificate: any, $store: any) => {
  // eslint-disable-next-line camelcase
  const { ...readyToBlockcerts } = certificate
  return {
    ...readyToBlockcerts,
    is_certified: false,
    certify: false,
    is_revocated: false,
    name: certificate.name
      ? certificate.name
      : $store.state.certsConfig.certsConfig.name,
    pubkey: certificate.pubkey
      ? `ecdsa-koblitz-pubkey:${certificate.pubkey}`
      : `ecdsa-koblitz-pubkey:${$store.state.certsConfig.certsConfig.pubkey}`,
    identity: certificate.identity
      ? certificate.identity
      : $store.state.certsConfig.certsConfig.identity,
    created: firebase.firestore.Timestamp.now(),
  }
}

export const getAllCertificates = async ($fire: any, collection: string) => {
  return await $fire.firestore.collection(collection).get()
}

export const getCertificate = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('certificates').doc(id).get()
}

export const setCertificate = async (
  $fire: any,
  data: Certificate,
  collection: string
) => {
  return await $fire.firestore.collection(collection).add(data)
}

export const deleteCertificate = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('certificates').doc(id).delete()
}

export const deleteNotifications = async ($fire: any, email: string) => {
  const notifications = await $fire.firestore.collection(
    'notification2&3Emails'
  )

  notifications
    .where('options.email', '==', email)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(async (doc: any) => {
        // Función que realiza correctamente una escritura
        await updateDocFirestore($fire, `notification2&3Emails/${doc.id}`, {
          status: 'cancelled',
        })
      })
    })
}

export const updateCertificate = async (
  $fire: any,
  id: string,
  body: any,
  collection: string
) => {
  return await setDocFirestore($fire, `${collection}/${id}`, { ...body }, true)
}

export const getIdReferenceFromDigitalIdentity = async (
  $fire: any,
  uid: string
) => {
  return await $fire.firestore
    .collection('certificates')
    .where('id_user', '==', uid)
    .where('type', '==', 'digital-identity')
    .limit(1)
    .get()
}

export const setBatchCertificates = async (
  $fire: any,
  docs: Certificate[],
  collection: string,
  $store: any
) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: Certificate) => {
    doc.is_certified = false
    doc.certify = false
    doc.is_revocated = false
    doc.name = $store.state.certsConfig.certsConfig.name
    doc.pubkey = doc.pubkey
      ? `ecdsa-koblitz-pubkey:${doc.pubkey}`
      : `ecdsa-koblitz-pubkey:${$store.state.certsConfig.certsConfig.pubkey}`
    doc.identity = $store.state.certsConfig.certsConfig.identity
    doc.created = format(new Date(), 'yyyy-MM-dd')

    const docRef = $fire.firestore.collection(collection).doc()
    batch.set(docRef, doc)
  })
  return await batch.commit()
}

export const updateBatchCertificates = async (
  $fire: any,
  docs: any,
  collection: string
) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    const id_reference = doc.id_reference
    // delete doc.id_reference
    const docRef = $fire.firestore.collection(collection).doc(id_reference)
    batch.update(docRef, doc)
  })
  return await batch.commit()
}

export const getCertificatesByUser = async (
  $fire: any,
  userUid: string,
  collection: string
) => {
  const matchValue = userUid
  return await $fire.firestore
    .collection(collection)
    .where('id_user', '==', matchValue)
    .where('is_certified', '==', true)
    .get()
}

export const getCertificateDataByUser = async (
  $fire: any,
  certCollection: any,
  userUID: any
) => {
  try {
    const snapshot = await $fire.firestore
      .collection(certCollection)
      .where('id_user', '==', userUID)
      .get()

    if (!snapshot.empty) {
      const data = { ...snapshot.docs[0].data() }
      return data
    } else {
      return { status: 'open' }
    }
  } catch {
    return { status: 'error' }
  }
}
