import firebase from 'firebase/compat/app'

export const getSettingsProcedures = async ($fire: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .doc('settings')
    .get()
}

export const getLastProcedure = async ($fire: any) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .limit(1)
    .get()
}

export const getProcedure = async ($fire: any, id: string) => {
  return await $fire.firestore
    .collection('/modules/ventanillaDigital/procedures')
    .doc(id)
    .get()
}

export const saveProcedureRating = async (
  $fire: any,
  path: string,
  body: any
) => {
  const data = { ...body }
  data.createdAt = firebase.firestore.Timestamp.now()
  return await $fire.firestore.collection(`${path}/ratings`).add(data)
}
