import { setDocFirestore } from '@/services/firebaseWrite'

export const getCertsConfigById = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('certsConfig').doc(id).get()
}

export const getCertsConfig = async ($fire: any) => {
  return await $fire.firestore.collection('certsConfig').limit(1).get()
}

export const updateCertsConfig = async ($fire: any, id: string, body: any) => {
  return await setDocFirestore($fire, `certsConfig/${id}`, { ...body }, true)
}
