export const getAllModules = async ($fire: any) => {
  return await $fire.firestore.collection('modules').get()
}

export const getLastModule = async ($fire: any) => {
  return await $fire.firestore.collection('modules').limit(1).get()
}

export const getModule = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('modules').doc(id).get()
}
